import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"

import NetworkingImage from "../../Images/Networking.png";


const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"Networking guide"} link={"https://gateway.allorado.io/docs/5.%20Networking.pdf"}>
        <div className={classes.docContainer}>

          <p>The Allorado Gateway has two Ethernet ports:</p>
          
          <ul>
            <li>The left port has a static IP address for easy configuration</li>
            <li>The right port is configured as DHCP, to connect to your network</li>
          </ul>

          <img src={NetworkingImage} className={classes.networkingImage} alt="Networking diagram displaying which ports are configured how."/>
          <h3>Left port (P2)</h3>
          <ul>
            <li>Static IP address 192.168.10.100</li>
            <li>Subnet mask 255.255.255.0</li>
          </ul>

          Steps to connect:
          <ol>
            <li>Plug your PC directly into this port</li>
            <li>Assign an IP in the same range to your PC, e.g. 192.168.10.50</li>
            <li>Surf to <div className={classes.inlineCode}>http://192.168.10.100</div> to access the Allorado Gateway</li>
          </ol>

          <h3>Right port (P1)</h3>
          <ol>
            <li>This port is configured as DHCP</li>
            <li>Plug this port into your local network, it gets an IP from your router</li>
            <li>Find the gateway's IP address by consulting your router</li>
            <li>Surf to the address to access the Allorado Gateway</li>
          </ol>

          <h3>Adjusting default settings</h3>
          <ol>
            <li>Log into the web interface</li>
            <li>Head over to “Network” in the “Configuration” section</li>
            <li>Configure the network interfaces as desired</li>
            <li>Apply the settings</li>
          </ol>

          <div className={classes.standaloneTip}>
            <div><b>Note</b>:</div>
            <div>Due to new settings, the IP address of the gateway might change. Find the new IP address and surf to that address in your browser.</div>
          </div>
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
