import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"Gateway specifications"} link={"https://gateway.allorado.io/docs/1.%20Specification%20sheet.pdf"}>
        <div className={classes.docContainer}>
          <h3>1. Industrial gateway specifications</h3>
          <div className={classes.tableContainer}>
          <table className={classes.specTable}>
            <tbody>
            <tr>
              <td>Processor</td>
              <td>TI AM6548 HS quad-core ARM</td>
            </tr>
            <tr>
              <td>Memory</td>
              <td>2 GB DDR4</td>
            </tr>
            <tr>
              <td>Storage</td>
              <td>16 GB eMMC flash</td>
            </tr>
            <tr>
              <td>Connectivity</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>2x Ethernet 100/1000 Mbps</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>1x USB 3.0 Type A</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>1x USB 2.0 Type A</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>1x DisplayPort 1.1 A</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>1x COM port (RS 232 / 422 / 485)</td>
            </tr>
            <tr>
              <td>Operating temperature</td>
              <td>0&deg;C to 50&deg;C</td>
            </tr>
            <tr>
              <td>Protection rating</td>
              <td>IP20</td>
            </tr>
            <tr>
              <td>Mounting</td>
              <td>DIN rail / wall mounted</td>
            </tr>
            <tr>
              <td>Input voltage</td>
              <td>12 to 24 V DC</td>
            </tr>
            </tbody>
          </table>
          </div>
          
          <h3>2. LoRa radio specifications</h3>
          <div className={classes.tableContainer}>
          <table className={classes.specTable}>
            <tbody>
            <tr>
              <td>Semtech radio</td>
              <td>SX1301</td>
            </tr>
            <tr>
              <td>External antenna</td>
              <td>50 Ω impedance</td>
            </tr>
            <tr>
              <td>Antenna connector</td>
              <td>RP-SMA Female</td>
            </tr>
            <tr>
              <td>Frequency band</td>
              <td>863 MHz to 870 MHz (others available)</td>
            </tr>
            <tr>
              <td>Sensitivity</td>
              <td>Up to -124 dBm at SF7</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>Up to -138 dBm at SF12</td>
            </tr>
            <tr>
              <td>Max RF output</td>
              <td>Up to +21 dBm</td>
            </tr>
            <tr>
              <td>Operating temperature</td>
              <td>-40&deg;C to +75&deg;C</td>
            </tr>
            </tbody>
          </table>
          </div> 

          <div className={classes.reference}><b>[1]</b> For more information, consult <a className={classes.docLink} href={"https://cache.industry.siemens.com/dl/files/016/109779016/att_1018122/v2/iot2050_operating_instructions_en_en-US.pdf"} target={"_blank"} rel="noreferrer">Siemens documentation.</a></div>
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
